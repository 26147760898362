import React from 'react';
//@ts-ignore
import * as styles from './customHeadline.module.css';
import { graphql } from 'gatsby';
//@ts-ignore
import {
    KontentStringValue,
    KontentRadioMultiChoice,
    BrandColor,
} from '../../../../types/KontentProps';
import { getColor, getMultiChoice } from '../../Global/DataUtils';

type HeaderTagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type HeadingStyleType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type HeadlineAlignmentType = 'right' | 'left' | 'center' | undefined;

interface Props {
    component_anchor_name?: KontentStringValue;
    tag?: KontentRadioMultiChoice<HeaderTagType>;
    style: KontentRadioMultiChoice<HeadingStyleType>;
    headline_value: KontentStringValue;
    color?: BrandColor;
    overrideStyles?: React.CSSProperties;
    alignment?: KontentRadioMultiChoice<HeadlineAlignmentType>;
}

const CustomHeadline: React.FC<Props> = (props) => {
    let { tag, style, headline_value, color, alignment, overrideStyles } =
        props;
    const textColor = getColor(color);
    const HeaderTag = typeof tag != 'string' ? getMultiChoice(tag) : tag;
    const StyleTag = style;

    const headlineAlignment = getMultiChoice(alignment);
    const headlineAlignmentCase = headlineAlignment
        ? headlineAlignment.toLowerCase()
        : null;

    let headlineAlignPosition = setInnerContentPosition(headlineAlignmentCase);
    function htmlHeader(header: any) {
        switch (header) {
            case 'h1':
                return 'h1';
                break;
            case 'h2':
                return 'h2';
                break;
            case 'h3':
                return 'h3';
                break;
            case 'h4':
                return 'h4';
                break;
            case 'h5':
                return 'h5';
                break;
            case 'h6':
                return 'h6';
                break;
            default:
                return 'div';
        }
    }

    const componentStyles = overrideStyles
        ? { ...headlineAlignPosition, ...overrideStyles }
        : headlineAlignPosition;

    return (
        <>
            {!headline_value?.value.includes('\n')
                ? React.createElement(
                      htmlHeader(HeaderTag),
                      {
                          style: {
                              color: textColor,
                              style: StyleTag,
                              ...componentStyles,
                          },
                          className:
                              styles[StyleTag] + ' ' + styles.headlineStyle,
                          id: props.component_anchor_name?.value,
                      },
                      <>{headline_value?.value}</>
                  )
                : headline_value?.value.split('\n').map((text, index) => {
                      return React.createElement(
                          htmlHeader(HeaderTag),
                          {
                              style: {
                                  color: textColor,
                                  style: StyleTag,
                                  ...componentStyles,
                              },
                              className:
                                  styles[StyleTag] + ' ' + styles.headlineStyle,
                              id: props?.component_anchor_name?.value
                                  ? props?.component_anchor_name?.value + index
                                  : index,
                          },
                          <>{text}</>
                      );
                  })}
        </>
    );
};

export default CustomHeadline;

function setInnerContentPosition(headlineAlignmentCase: HeadlineAlignmentType) {
    if (headlineAlignmentCase == 'left') {
        return {
            textAlign: 'left',
        } as React.CSSProperties;
    }

    if (headlineAlignmentCase == 'right') {
        return {
            textAlign: 'right',
        } as React.CSSProperties;
    }
    if (headlineAlignmentCase == 'center') {
        return {
            textAlign: 'center',
        } as React.CSSProperties;
    }

    return {};
}
