import React from 'react';
import { graphql } from 'gatsby';
//@ts-ignore
import GatsbyImage from 'gatsby-image/withIEPolyfill';
//@ts-ignore
import * as styles from './global-masthead.module.css';
import CtaLink from '../Global/CtaLink';
import { BuildLinkHref } from '../Global/BuildLinkHref';
//@ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { MediaQueries } from '../Global/GlobalMediaQueries';
import {
    KontentStringValue,
    CTAConfiguration,
    GeneralImages,
    KontentRadioMultiChoice,
    KontentMedia,
    BrandColor,
    KontentNumberValue,
    CtaTsType,
    KontentStringCodenameValue,
    KontentRichText,
} from '../../../types/KontentProps';
import { getColor, getMediaData, getMultiChoice } from '../Global/DataUtils';
import { getFluidImageData } from '../Global/DataUtils/gatsbyImageUtils';
import PlainTextElement from '../Global/Headlines';
import CustomHeadline from '../Global/CustomHeadline';

type ContentPositionType = 'right' | 'left' | 'center' | undefined;
type MastheadStyles = 'large' | 'short';
type ShowHide = 'show' | 'hide';
type HeadlineSource = 'page_title' | 'display_headline_field';
interface Props extends CTAConfiguration, GeneralImages {
    component_anchor_name?: KontentStringValue;
    content_position?: KontentRadioMultiChoice<ContentPositionType>;
    cta_show_or_hide: KontentRadioMultiChoice<ShowHide>;
    display_headline: KontentStringValue;
    display_sub_headline?: KontentStringValue;
    masthead_style: KontentRadioMultiChoice<MastheadStyles>;
    masthead_video?: KontentMedia;
    page_headline_source?: KontentRadioMultiChoice<HeadlineSource>;
    text_background_color?: BrandColor;
    text_background_opacity?: KontentNumberValue;
    under_image_bar_color?: BrandColor;
    under_image_bar_show_or_hide: KontentRadioMultiChoice<ShowHide>;
    tablet_image: KontentMedia;
    cta2_show_or_hide?: KontentRadioMultiChoice<ShowHide>;
    cta2?: {
        value: CtaTsType[];
    };
    display_headline_tag: KontentStringCodenameValue;
    display_sub_headline_tag?: KontentStringCodenameValue;
    description_copy?: KontentRichText;
    description_tag?: KontentStringCodenameValue;
}

const GlobalMasthead: React.FC<Props> = ({
    component_anchor_name,
    cta_configuration__cta_label,
    cta_configuration__cta_location,
    cta_configuration__cta_location_anchor_point,
    cta_configuration__cta_window_action,
    content_position,
    cta_show_or_hide,
    display_headline,
    display_sub_headline,
    general_large___small_image_selection__large,
    general_large___small_image_selection__small,
    masthead_style,
    masthead_video,
    page_headline_source,
    text_background_color,
    text_background_opacity,
    under_image_bar_color,
    under_image_bar_show_or_hide,
    tablet_image,
    cta2_show_or_hide,
    cta2,
    display_headline_tag,
    display_sub_headline_tag,
    description_copy,
    description_tag,
}) => {
    const SecondCta = cta2?.value[0]?.elements;
    const anchorName = component_anchor_name?.value;
    const underImageBarShowHide =
        getMultiChoice(under_image_bar_show_or_hide) === 'show';
    const mastheadStyle = getMultiChoice(masthead_style);
    const mastheadBaseHeight = mastheadStyle === 'short' ? '321px' : '100vh';
    const mastheadAdjustedHeight =
        underImageBarShowHide && mastheadStyle === 'large'
            ? { height: `calc(${mastheadBaseHeight} - 100px)` }
            : { height: mastheadBaseHeight };
    const mastheadHeightStyle =
        mastheadStyle === 'short'
            ? { marginTop: '96px', ...mastheadAdjustedHeight }
            : mastheadAdjustedHeight;

    const video = getMediaData(masthead_video);
    const contentPosition = getMultiChoice(content_position);
    const textBackgroundColor = getColor(text_background_color);
    const textBackgroundOpacity = text_background_opacity?.value
        ? text_background_opacity.value / 100
        : 1;
    const ctaShowHide =
        getMultiChoice(cta_show_or_hide) === 'show' &&
        cta_configuration__cta_label?.value;
    const cta2ShowHide =
        getMultiChoice(cta2_show_or_hide) === 'yes' &&
        SecondCta?.cta_label?.value;

    const ctaWindowAction = getMultiChoice(
        cta_configuration__cta_window_action
    );
    const cta2windowAction = getMultiChoice(SecondCta?.cta_window_action);
    const breakpoints = useBreakpoint();
    let innerContentPosition = setInnerContentPosition(
        contentPosition,
        breakpoints
    );
    const renderImages = () => {
        let largeImageData =
            general_large___small_image_selection__large?.value[0] &&
            getFluidImageData(general_large___small_image_selection__large);

        if (mastheadStyle === 'short' && largeImageData) {
            largeImageData.source =
                general_large___small_image_selection__large?.value[0]?.elements.file?.value[0]?.fluidShort;
        }

        let smallImageData =
            general_large___small_image_selection__small?.value[0] &&
            getFluidImageData(general_large___small_image_selection__small);

        if (mastheadStyle === 'short' && smallImageData) {
            smallImageData.source =
                general_large___small_image_selection__small?.value[0]?.elements.file?.value[0]?.fluidShort;
        }

        let tabletImageData =
            tablet_image?.value[0] && getFluidImageData(tablet_image);

        if (mastheadStyle === 'short' && tabletImageData) {
            tabletImageData.source =
                tablet_image?.value[0]?.elements.file?.value[0]?.fluidShort;
        }

        if (!largeImageData?.source) return null;

        const baseobj = {
            aspectRatio: 1,
            base64: '',
            sizes: '',
            src: '',
            srcSet: '',
        };
        const sources = smallImageData
            ? [
                  { ...smallImageData?.source, media: '(max-width: 576px)' },
                  {
                      ...baseobj,
                      ...tabletImageData?.source,
                      media: '(max-width: 1024px)',
                  },
                  {
                      ...largeImageData?.source,
                      media: '(min-width: 577px)',
                  },
              ]
            : largeImageData?.source;

        return (
            <GatsbyImage
                //@ts-ignore
                fluid={sources}
                className={styles.picture}
                style={{ overflow: 'visible' }}
                imgStyle={{ height: mastheadBaseHeight }}
                alt={largeImageData?.alt}
                fadeIn={false}
            />
        );
    };

    const renderVideo = () => {
        return (
            <video muted={true} autoPlay={true} loop={true} playsInline={true}>
                <source src={video?.url} type="video/mp4"></source>
            </video>
        );
    };

    const isLongSingleWord =
        display_headline?.value?.split(' ')?.length === 1 &&
        display_headline.value.length > 12
            ? true
            : false;

    return (
        <section
            id={anchorName || ''}
            className={styles.wrapper}
            style={mastheadHeightStyle}
        >
            <div
                className={styles.contentContainer}
                style={{ height: mastheadHeightStyle.height }}
            >
                {video?.url ? renderVideo() : renderImages()}
                {textBackgroundColor && (
                    <div
                        className={styles.bgOverlay}
                        style={{
                            backgroundColor: textBackgroundColor,
                            opacity: textBackgroundOpacity,
                        }}
                    ></div>
                )}
            </div>
            <div className={styles.innerContentContainer}>
                <div
                    className={styles.innerContent}
                    style={innerContentPosition}
                >
                    {display_sub_headline?.value && (
                        <div className-={styles.subheadlineContainer}>
                            <CustomHeadline
                                style={'h5'}
                                tag={
                                    display_sub_headline_tag?.value[0]?.codename
                                        ? display_sub_headline_tag
                                        : {
                                              value: [
                                                  {
                                                      codename: 'h2',
                                                      name: 'h2',
                                                  },
                                              ],
                                          }
                                }
                                headline_value={display_sub_headline}
                            />
                        </div>
                    )}
                    <div className={styles.headlineContainer}>
                        <CustomHeadline
                            style={'h1'}
                            tag={
                                display_headline_tag?.value[0]?.codename
                                    ? display_headline_tag
                                    : {
                                          value: [
                                              { codename: 'h1', name: 'h1' },
                                          ],
                                      }
                            }
                            headline_value={display_headline}
                            overrideStyles={{ margin: '0px 0px' }}
                        />
                    </div>

                    {description_copy?.value && (
                        <div className={styles.descriptionContainer}>
                            <CustomHeadline
                                style={'h5'}
                                tag={
                                    description_tag?.value[0]?.codename
                                        ? description_tag
                                        : {
                                              value: [
                                                  {
                                                      codename: 'h2',
                                                      name: 'h2',
                                                  },
                                              ],
                                          }
                                }
                                headline_value={description_copy}
                            />
                        </div>
                    )}
                    <div
                        className={`${styles.ctas} ${
                            cta2ShowHide && styles.ctasWithSameWidth
                        }`}
                    >
                        <div className={styles.cta1}>
                            {mastheadStyle === 'large' && ctaShowHide ? (
                                <CtaLink
                                    href={BuildLinkHref(
                                        cta_configuration__cta_location,
                                        cta_configuration__cta_location_anchor_point
                                    )}
                                    target={
                                        ctaWindowAction === 'new_window'
                                            ? '_blank'
                                            : undefined
                                    }
                                    visualStyle="outlineLight"
                                >
                                    {cta_configuration__cta_label?.value}
                                </CtaLink>
                            ) : null}
                        </div>
                        {mastheadStyle === 'large' && cta2ShowHide ? (
                            <div className={styles.cta2}>
                                <CtaLink
                                    href={BuildLinkHref(
                                        SecondCta?.cta_location,
                                        SecondCta?.cta_location_anchor_point
                                    )}
                                    target={
                                        cta2windowAction === 'new_window'
                                            ? '_blank'
                                            : undefined
                                    }
                                    visualStyle="outlineLight"
                                >
                                    {SecondCta?.cta_label.value}
                                </CtaLink>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            {underImageBarShowHide && (
                <div
                    className={styles.underImageBar}
                    style={{
                        backgroundColor: getColor(under_image_bar_color) as any, // find BackgroundColor type
                    }}
                ></div>
            )}
        </section>
    );
};

export default GlobalMasthead;

export const fragmentGlobalMasthead = graphql`
    fragment GlobalMasthead on kontent_item_component___global_masthead {
        id
        elements {
            display_headline {
                value
            }
            component_anchor_name {
                value
            }
            content_position {
                value {
                    codename
                }
            }
            cta_configuration__cta_label {
                value
            }
            cta2_show_or_hide {
                value {
                    codename
                }
            }
            cta2 {
                value {
                    ...kontentCta
                }
            }
            cta_configuration__cta_location {
                ...kontentCtaLocation
            }
            cta_configuration__cta_location_anchor_point {
                value
            }
            cta_configuration__cta_window_action {
                value {
                    codename
                }
            }
            cta_show_or_hide {
                value {
                    codename
                }
            }
            display_sub_headline {
                value
            }
            display_headline_tag {
                value {
                    codename
                    name
                }
            }
            display_sub_headline_tag {
                value {
                    codename
                    name
                }
            }
            description_copy {
                value
            }
            description_tag {
                value {
                    codename
                    name
                }
            }
            general_large___small_image_selection__large {
                ...kontentImage
                value {
                    ... on kontent_item_media___image {
                        id
                        elements {
                            file {
                                value {
                                    fluid(maxWidth: 1920, maxHeight: 988) {
                                        ...KontentAssetFluid
                                    }
                                    fluidShort: fluid(
                                        maxWidth: 1920
                                        maxHeight: 321
                                    ) {
                                        ...KontentAssetFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
            general_large___small_image_selection__small {
                ...kontentImage
                value {
                    ... on kontent_item_media___image {
                        id
                        elements {
                            file {
                                value {
                                    fluid(maxWidth: 720, maxHeight: 1080) {
                                        ...KontentAssetFluid
                                    }
                                    fluidShort: fluid(
                                        maxWidth: 720
                                        maxHeight: 512
                                    ) {
                                        ...KontentAssetFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
            tablet_image {
                ...kontentImage
                value {
                    ... on kontent_item_media___image {
                        id
                        elements {
                            file {
                                value {
                                    fluid(
                                        maxWidth: 1024
                                        maxHeight: 1366
                                        quality: 100
                                    ) {
                                        ...KontentAssetFluid
                                    }
                                    fluidShort: fluid(
                                        maxWidth: 1024
                                        maxHeight: 321
                                        quality: 100
                                    ) {
                                        ...KontentAssetFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
            masthead_style {
                value {
                    codename
                }
            }
            masthead_video {
                value {
                    ... on kontent_item_media___masthead_video {
                        elements {
                            file {
                                value {
                                    url
                                    description
                                    name
                                }
                            }
                        }
                    }
                }
            }
            page_headline_source {
                value {
                    codename
                }
            }
            text_background_color {
                ...kontentColor
            }
            text_background_opacity {
                value
            }
            under_image_bar_color {
                ...kontentColor
            }
            under_image_bar_show_or_hide {
                value {
                    codename
                }
            }
        }
    }
`;

function setInnerContentPosition(
    contentPosition: ContentPositionType,
    breakpoints: MediaQueries
) {
    if (contentPosition == 'right' && breakpoints.md) {
        return {
            position: 'absolute',
            left: '50%',
            transform: 'translateY(-50%)',
            maxWidth: '740px',
            width: '48%',
        } as React.CSSProperties;
    }

    if (contentPosition == 'center') {
        return {
            margin: '0 auto',
        } as React.CSSProperties;
    }

    return {};
}
